// import dashboard from './dashboard';
// import widget from './widget';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';
// import tweetsy from './tweetsy';
// import adminTweetsy from './admin_tweetsy';
// import dashboard from './dashboard';
import dashboard from './dashboard';
// import output from './output';
// import campaignSetup from './campaignSetup';
import account from './account';
import integrate from './integrate';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, integrate, account]
    // admin_items: [adminTweetsy]
};

export default menuItems;
